import React from 'react'
import Layout from '../components/core/Layout'
import Seo from '../components/core/SEO'

const NotFoundPage = () => (
  <Layout>
    <Seo pageMeta={{ title: 'Not Found' }} />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
